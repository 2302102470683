<template>
  <div class="wrap">
    <div class="The_header">
      <ul class="bg-fff">
        <li class="left">
          <router-link to>
            <i class="iconfont icon-fanhui" @click="$router.back()"></i>
          </router-link>
        </li>
        <li>{{t('extra.bankPay')}}</li>
      </ul>
    </div>
    <div class="main main_page">
      <AForm
        layout="vertical"
        :model="formModel"
        ref="formRef"
        :rules="ruleRef"
        validateTrigger="blur"
        @submit.prevent="handleRecharge"
        autocomplete="off"
      >
        <div class="Pay_usdt">
          <div class="Pay_usdt_box">
            <h1>
              {{ t("extra.rechageText1") }}
            </h1>
            <!-- <ol class="ol_box">
              <h3>{{t('profile.label.amount')}}:</h3>
              <li>
                <b>{{ formModel.amount }}</b>
              </li>
            </ol> -->
          </div>
          <div class="Rech">
            <div class="Rech_box">
              <div class="Pay_usdt_box" id="input_C">
                <ul class="amountUl">
                    <li class="flexLi">
                      <b>{{ CURRENCY_SYMBOL }}</b>
                      <!-- step="any" -->
                      
                      <AFormItem name="amount">
                        <AInput
                          type="number"
                          v-model:value="formModel.amount"
                          step="any"
                          :placeholder="t('profile.placeholder.rechargeAmount')"
                        />
                      </AFormItem>
                      <!-- <AFormItem name="amount">
                        <AInput
                        type="number"
                          v-model:value="formModel.amount"
                          :placeholder="t('profile.placeholder.rechargeAmount')"
                        />
                      </AFormItem> -->
                    </li>
                  </ul>
                </div>
              </div>
          </div>
          <a-radio-group v-model:value="channelId" id="rechargeeBankRadioGroup">
            <a-radio
              :value="item.id"
              v-for="(item, index) in activeSysBankcard"
              :key="index"
            >
              <div class="Pay_usdt_box">
                <h3>{{t('extra.BeneficiaryBank')}}:</h3>
                <div class="Beneficiary">
                  <p>
                    <b>{{t('extra.bankName')}}:</b>
                    <span>{{ item.bankName }}</span>
                    <button
                      type="button"
                      @click.prevent="doCopy(item.bankName)"
                    >
                      {{ t("common.button.copy") }}
                    </button>
                  </p>
                  <p>
                    <b>{{t('profile.label.accountNo')}}:</b>
                    <span>{{ item.acctNo }}</span>
                    <button type="button" @click.prevent="doCopy(item.acctNo)">
                      {{ t("common.button.copy") }}
                    </button>
                  </p>
                  <p>
                    <b>{{t('extra.holderName')}}:</b>
                    <span>{{ item.acctName }}</span>
                    <button
                      type="button"
                      @click.prevent="doCopy(item.acctName)"
                    >
                      {{ t("common.button.copy") }}
                    </button>
                  </p>

                  <p v-if="sysDataDictionaryWbObj?.TKCI?.dataOnOff">
                    <b>{{sysDataDictionaryWbObj?.TKCI?.dataContent}}:</b>
                    <span>{{ item.payDevice }}</span>
                    <button
                      type="button"
                      @click.prevent="doCopy(item.payDevice)"
                    >
                      {{ t("common.button.copy") }}
                    </button>
                  </p>

                  <p>
                    {{ t("common.recharge_online.min") }}
                    {{ formatMoney(item.minMoney) }}
                  </p>
                  <p>
                    {{ t("common.recharge_online.max") }}
                    {{ formatMoney(item.maxMoney) }}
                  </p>
                  
                </div>
              </div>
            </a-radio>
          </a-radio-group>
          <div class="Pay_usdt_box" v-if="sysDataDictionaryWbObj?.YHKCZBZ?.dataOnOff">
            <ul>
              <h3>{{t('extra.remark')}}:</h3>
              <li>
                <AFormItem name="remark2">
                  <AInput
                    v-model:value="formModel.remark2"
                    :placeholder="t('common.placeholder.remark')"
                  />
                </AFormItem>
              </li>
            </ul>
          </div>
          <div class="Pay_usdt_box" v-if="sysDataDictionaryWbObj?.SCPZ?.dataOnOff">
            <!-- <ul>
              <h3>{{t('extra.bankName')}}:</h3>
              <li>
                <AFormItem name="srcBankName">
                  <AInput
                    v-model:value="formModel.srcBankName"
                    :placeholder="t('profile.placeholder.bank')"
                  />
                </AFormItem>
              </li>
            </ul> -->
            <!-- <ul>
              <h3>{{t('profile.label.accountNo')}}:</h3>
              <li>
                <AFormItem name="srcAcctNo">
                  <AInput
                    v-model:value="formModel.srcAcctNo"
                    :placeholder="t('profile.placeholder.accountNo')"
                  />
                </AFormItem>
              </li>
            </ul>-->
            <ul > 
              <!-- <h3>{{t('extra.holderName')}}:</h3>
              <li>
                <AFormItem name="srcAcctName">
                  <AInput
                    v-model:value="formModel.srcAcctName"
                    :placeholder="t('profile.placeholder.accountName')"
                  />
                </AFormItem>
              </li> -->
              
              <li class="li_Upload" >
                <div class="Upload">
                  <Upload
                    action="/sys/upload/file"
                    list-type="picture-card"
                    accept="image/png,image/jpeg"
                    v-model:file-list="images"
                    @change="handleUploadChange"
                    :show-upload-list="false"
                  >
                    <img
                      v-if="!imgIsLoading && images.length > 0"
                      :src="images?.[0]?.response?.data?.url"
                      alt="avatar"
                      class="imgClass"
                    />
                    <div v-else>
                      <loading-outlined v-if="loading"></loading-outlined>
                      <plus-outlined v-else></plus-outlined>
                      <div class="ant-upload-text">{{t('common.button.upload')}}</div>
                    </div>
                  </Upload>
                </div>
              </li>
            </ul>
          </div>

          <div class="Sys_submit_btn recharge">
            <button type="submit">{{ t("common.button.submit") }}</button>
          </div>
        </div>
      </AForm>
    </div>
    <Loading :loading="loading" />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  getCurrentInstance
} from "vue";
import { PlusOutlined } from "@ant-design/icons-vue";
// import { useRoute, useRouter } from "vue-router";
import { useRouter } from "vue-router";
import { Upload, message, Modal } from "ant-design-vue";
import { useI18n } from "@/lang";
import api from "@/api";
import { CURRENCY_SYMBOL } from '@/utils/constant';
// import { copyText } from 'vue3-clipboard';
import { copyText } from "vue3-clipboard";
import Loading from "@/components/Loading.vue";
import { formatMoney } from "@/utils/formatter";
import { sysDataDictionaryWbObj } from "@/hooks/sysDataDictionaryWbObj";

export default defineComponent({
  name: "RechargePay",
  components: { Upload, PlusOutlined, Loading },
  setup() {
    onMounted(async () => {
      let res = await api.getPayChannelList();
      let list =
        res && res.data && res.data.data && res.data.data.localPayChannelPoList;
      if (list) {
        channelId.value = list[0].id
        // console.log(list,'--------',list[0].id)
        activeSysBankcard.value = list.map((item) => {
          return {
            bankName: item.bankName,
            acctNo: item.accountNumber,
            acctName: item.accountName,
            id: item.id,
            minMoney:item.minMoney,
            maxMoney:item.maxMoney,
            payDevice:item.payDevice
          };
        });
      }
    });
    const { proxy } = getCurrentInstance();
    // const {
    //   params: { amount },
    // } = useRoute();
    const loading = ref(false);
    const router = useRouter();
    const channelId = ref(null);
    const activeSysBankcard = ref(null);
    const imgIsLoading = ref(false);
    const { t,locale } = useI18n();

    const formModel = reactive({
      amount: "",
      images: [],
      remark2: ""
      // srcBankName: "",
      // srcAcctNo: "",
      // srcAcctName: ""
    });

    const images = ref([]);

    const ruleRef = reactive({
      amount: [
        {
          required: true,
          message: t("profile.placeholder.rechargeAmount"),
        },
        {
          validator: async (_rule, val) => {
            if (+val < 0.01) {
              return Promise.reject(t("profile.placeholder.rechargeAmount"));
            } else {
              if (
                ("" + val).indexOf(".") != -1 &&
                ("" + val).slice(("" + val).indexOf(".")).length >= 4
              ) {
                return Promise.reject(t("error.recharge.decimalErr"));
              }
              return Promise.resolve();
            }
          },
        },
      ],
      // srcBankName: [
      //   {
      //     required: true,
      //     type: "string",
      //     message: t('profile.placeholder.bank'),
      //   },
      // ],
      // srcAcctName: [
      //   {
      //     required: true,
      //     type: "string",
      //     message: t('profile.placeholder.accountName'),
      //   },
      // ],
      // srcAcctNo: [
      //   {
      //     required: true,
      //     type: "string",
      //     message: t('profile.placeholder.accountNo'),
      //   },
      // ],
    });
    // //限制输入两位
    // async function checkValue(e) {
    //   let newV = e.target.value;
    //   newV = '' + newV
    //   newV = newV.replace(/[^\d.]/g, '')
    //   newV = newV.replace(/^\./g, '')
    //   newV = newV.replace(/\.{2,}/g, '.')
    //   newV = newV.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
    //   newV = newV.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
    //   formModel.amount = newV;
    // }

    function handleUploadChange(info) {
      if (info.file.status === "uploading") {
        imgIsLoading.value = true;
        message.loading({
          content: "uploading",
          key: "recharge",
        });
      }

      if (info.file.status === "done") {
        imgIsLoading.value = false;
        formModel.images[0] = info.file.response.data.key;
        images.value = images.value.slice(-1);
        message.destroy();
      }

      if (info.file.status === "error") {
        imgIsLoading.value = false;
        message.error({
          content: t("error.imageUploadError"),
          key: "recharge",
          duration: 3,
        });
      }
    }

    async function handleRecharge() {
      
      if(locale.value === 'tur'){
        if(!formModel.amount || formModel.amount <= 0){
           message.error({
            content: t("profile.placeholder.rechargeAmount"),
            key: "recharge",
            duration: 3,
          });
          return
        }
      }
      if(locale.value !== 'tur'){
          let validateResult = await proxy.$refs["formRef"]
          .validate()
          .then((e) => {
            return e;
          })
          .catch((e) => {
            return e;
          });
        if (
          !(
            validateResult &&
            validateResult.errorFields &&
            validateResult.errorFields.length === 0
          )
        ) {
          return;
        }
        if (formModel.images.length < images.value.length) {
          return;
        }
      }
      
      let maxMoney = 0
      let minMoney = 0
      for (let i = 0; i < activeSysBankcard.value.length; i++) {
        if(+activeSysBankcard.value[i].id === +channelId.value){
          maxMoney = activeSysBankcard.value[i].maxMoney
          minMoney = activeSysBankcard.value[i].minMoney
          break;
        }
      }
      // console.log(maxMoney,'---111',minMoney)
      if ((maxMoney!==0&&minMoney!==0) && (+minMoney > +formModel.amount || +formModel.amount > +maxMoney)){
        message.info(
          t("error.recharge.amountErr", {
            max: maxMoney,
            min: minMoney,
          })
        );
        return
      }
      // if(locale.value !== 'tur'){
        if (sysDataDictionaryWbObj.value.SCPZ && formModel.images.length === 0) {
          message.error({
            content: t("error.recharge.screenshotsRequired"),
            key: "recharge",
            duration: 3,
          });

          return;
        }
      // }
      if (!channelId.value) {
        message.error({
            content: t("error.recharge.selectBank"),
            key: "recharge",
            duration: 3,
          });
        // console.log('1111111111111111111111111111')
        return;
      }

      try {
        loading.value = true;
        let formToSend = {
          amount: formModel.amount,
          payImg: formModel.images?.[0],
          realName: formModel.srcAcctName,
          bankName: formModel.srcBankName,
          accountNumber: formModel.srcAcctNo,
          channelId: channelId.value,
          remark2: formModel.remark2
        };
        let res = await api.localPay(formToSend);
        if (res && res.data && res.data.success) {
          Modal.success({
            title: t("common.title.rechargeOrderSubmitted"),
            width: "80%",
            centered: true,
            content: t("profile.hits.rechargeOrderSubmitted"),
            okText: t("common.button.goBack"),
            onOk: async () => {
              await router.back();
            },
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    }

    function doCopy(text) {
      copyText(text, undefined, (error, event) => {
        console.log(event);
        if (!error) {
          message.success({
            key: "copy",
            content: t("common.clipboard.success"),
          });
        }
      });
    }

    return {
      t,
      formModel,
      ruleRef,
      handleRecharge,
      PlusOutlined,
      images,
      handleUploadChange,
      activeSysBankcard,
      doCopy,
      channelId,
      loading,
      imgIsLoading,
      locale,
      CURRENCY_SYMBOL,
      formatMoney,
      sysDataDictionaryWbObj,
    };
  },
});
</script>

<style lang="scss">
#rechargeeBankRadioGroup {
  box-sizing: border-box;
  padding: 0px 5px;
  width: 100vw;
  margin-top: 10px;
  .ant-radio-wrapper {
    margin-bottom: 10px;
  }
}
.RechargeBankD {
}
.imgClass {
  width: 90px;
  height: 90px;
}
</style>
